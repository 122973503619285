<template>
  <BaseSelector
    :options="languages"
    @get:input="updateLanguage"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseLanguageSelector',
  data() {
    return {
      languages: [
        {
          option: 'EN',
          icon: 'i-circle-flags-uk',
        },
        {
          option: 'ES',
          icon: 'i-circle-flags-es',
        },
        {
          option: 'DE',
          icon: 'i-circle-flags-de',
        },
      ],
    }
  },
  methods: {
    updateLanguage(value) {
      // console.log(value)
      this.$i18n.locale = value.option.toLowerCase()
    },
  },
})
</script>
