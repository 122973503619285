<template>
  <div
    ref="target"
    class="relative rounded-md"
    tabindex="0"
  >
    <div
      class="flex items-center gap-4 p-2"
      @click="open = !open"
    >
      <slot>
        <Icon
          v-if="selected.icon"
          class="block h-full w-full object-cover object-center"
          size="24"
          :name="selected.icon"
        />
        <span class="text-base text-neutral-950 dark:text-neutral-50">{{
          selected.option
        }}</span>
        <Icon
          name="i-heroicons-chevron-up"
          size="14"
          :class="['chevron', { 'chevron--open': open }]"
        />
      </slot>
    </div>

    <transition
      name="fade-down"
      appear
    >
      <ul
        v-if="open"
        role="listbox"
        class="absolute top-[110%] z-10 flex w-full flex-col bg-blue-100 shadow-md dark:bg-black"
      >
        <li
          v-for="(option, i) of options"
          :key="i"
          :class="{ active: option === selected }"
          class="block cursor-pointer rounded-md p-2 text-base text-neutral-950 hover:bg-primary-500 dark:text-neutral-50"
          tabindex="0"
          role="option"
          :aria-selected="option === selected"
          @click="
            () => {
              selected = option;
              open = false;
              $emit('get:input', option);
            }
          "
          @keydown.enter="
            () => {
              selected = option;
              open = false;
              $emit('get:input', option);
            }
          "
        >
          <Icon
            v-if="option.icon"
            size="20"
            :name="option.icon"
            class="mr-4"
          />
          <span>{{ option.option }}</span>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

interface Option {
  option: string
  icon: string
}

interface Props {
  options: Option[]
  default?: string
}

const { default: defaultProp, options } = defineProps<Props>()

defineEmits<{ (e: 'get:input', selected: Option): void }>()

const selected = ref<Option>(
  defaultProp || (options.length > 0 ? options[0] : null),
)
const open = ref(false)

const target = ref(null)

onClickOutside(target, () => (open.value = false))
</script>

<style scoped>
img {
  height: 2.5rem;
}

.active {
  background-color: var(--color-primary);
}

.custom-select {
  position: relative;
  z-index: 5;
  text-align: left;
  outline: none;
  line-height: 47px;
}

.custom-select .selected {
  background-color: var(--color-black);
  border-radius: 6px;
  color: var(--color-white);
  padding-left: 1rem;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1rem;
  width: 0;
  height: 0;
  border: 5px solid transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: var(--color-black);
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 250ms ease-out;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1rem;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: var(--color-primary-light);
}

.chevron {
  flex-shrink: 0;
  display: flex;
  transform-origin: center;
  transition: transform 250ms ease-out;
  transform: translateY(-10%);

  &--open {
    transform: rotate(180deg);
  }
}
</style>
